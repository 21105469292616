<template>
    <div>
        
        <editor
            api-key="vx8fq0gg6zt52l1rdvvi20nhj7z5100assg08r19p4ssr9qq"
            
            
            tag-name="div"
            id="mceu_phi" 

            :value="value"      
            
            @onKeyUp="updateContent"   
            @onExecCommand="updateContent"   
            @onSetContent="updateContent"  
            @onPaste="updatePaste" 

            :init="editorConfig"
        />
    </div>
</template>

<script>
import app from '../store/app.js';
import Editor from '@tinymce/tinymce-vue';

export default {
    name: "tiny-mce2",
    components: {
        'editor': Editor
    },
    data() {
        return {
            app,
            instance: null,
        }
    },
    props: {
        value: {
            type: String,
            default: ''
        },

        editorConfig: {
            type: Object,
            default() {
                
                return {
                    
                    language: (this.$store.state.i18n.language == 'es-CO')? 'es': this.$store.state.i18n.language,
                    
                    menubar: false,
                    height: 300,
                    width: '98%',
                    paste_remove_styles_if_webkit: false,
                    paste_auto_cleanup_on_paste : false,
                    
                    resize_img_proportional: true,
                    toolbar_location: 'bottom',
                    init_instance_callback : function(editor) {                        
                        var freeTiny = document.querySelector('.tox .tox-notification--in');
                        freeTiny.style.display = 'none';
                    }                  
                };
            }
        },
    },
    methods:{
        updateContent(event, editor){
            
            this.$emit('input', editor.getContent());
            this.$emit('update:modelValue', editor.getContent())
        },
        updatePaste(e, editor) {

            var types, content;
            e.preventDefault();

            if (e && e.clipboardData && e.clipboardData.types && e.clipboardData.getData)
            {
                types = e.clipboardData.types;

                if (((types instanceof DOMStringList) && types.contains("text/html")) || (types.indexOf && types.indexOf('text/html') !== -1))
                {
                    content = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('text/html');
                }
                else{
                    content = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('text');
                }
            }else{

                content = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('text');
            }

            editor.execCommand('mceInsertContent', false, content);
        }
    } 
}
</script>

<style lang="scss">
.phi-post-editor {
    .mce-container {
        margin-left: auto;
        margin-right: auto;
    }
}
</style>